import React, { useState } from "react";
import api from "./api";

const App = () => {
    const [url, setUrl] = useState(null);
    const [sort_Url, setSortUrl] = useState(null);

    const sortUrl = async (e) => {
        e.preventDefault();
        try {
            const res = await api.post("add-url", { url });
            setSortUrl(res.data.url);
        } catch (err) {
            console.log("🚀 ~ file: App.js:10 ~ sortUrl ~ err:", err);
        }
    };

    return (
        <div className="container">
            <br />
            <br />
            <form onSubmit={sortUrl}>
                <input
                    type="url"
                    value={url}
                    onChange={(e) => setUrl(e.currentTarget.value)}
                    placeholder="Enter your URL"
                />
                <br />
                <br />
                <button className="btn">Short</button>
                <br />
                <br />
            </form>
            {sort_Url && (
                <div style={{ fontSize: 18 }}>Shorted URL: {sort_Url}</div>
            )}
        </div>
    );
};

export default App;
